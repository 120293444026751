import React, { Component } from 'react'
import { getClientsList, manageRCMUserOrg } from '../../../services/adminServices/clientVisibilityService';
import { getClientsListColumn } from './clientsListColumns';
import { MsgForNoData } from '../../../common/constants';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import OverlayProcessing from '../../../common/overlayProcessing';
import { arrayFilterByValue } from '../../../common/utilities';
import { message } from 'antd';


class ClientsList extends Component {

    constructor(props){
        super(props);

        this.state= {
            clientsList: [],
            filterdClientList: [],
            showOverlay: false,
            searchClientStr: ''
        }

        this.handleOnClientSelect = this.handleOnClientSelect.bind(this);
    }


    componentDidUpdate(prevProps){
        if(this.props.selectedUser && this.props.selectedUser !== prevProps.selectedUser){
            if(this.props.selectedUser.rcmUserId){
                this.setState({searchClientStr: ''}, () => {
                    this.fetchClientsList(this.props.selectedUser.rcmUserId);
                })
            }
        }

        if(this.props.refresh !== prevProps.refresh){
            this.setState({searchClientStr: '', clientsList: [], filterdClientList: []})
        }
    }

    fetchClientsList(email){
        this.setState({showOverlay: true}, () => {
            getClientsList(email).then(res => {
                this.setState({clientsList: res, filterdClientList: res, showOverlay: false}, () => {
                });
            })
            .catch(err => {
                console.error(err);
                this.setState({showOverlay: false, clientsList: [], filterdClientList: []});
                message.error('We are unable to pull client information');
            })
        })
            
    }

    handleOnClientSelect(row){
            if(row){
                if(this.props.selectedUser && this.props.selectedUser.rcmUserId){
                    let mode = row.isUserBelongToOrg && row.isUserBelongToOrg === 'Y' ? 'REMOVE' : 'ADD';
                    this.setState({showOverlay: true}, () => {
                        manageRCMUserOrg(mode, row.orgId, this.props.selectedUser.rcmUserId, row.pKid).then(res => {
                                this.fetchClientsList(this.props.selectedUser.rcmUserId);
                            })
                            .catch(err => {
                                console.error(err);
                            })
                    });
                }
            }
    }

    handleOnSearchClientChange(event){
        if(event.target.value === ''){
            this.setState({filterdClientList:  this.state.clientsList, searchClientStr: ''});
        }else{
            let filterArr =   arrayFilterByValue(this.state.clientsList, event.target.value, ['organizationName']);
            this.setState({filterdClientList: filterArr, searchClientStr: event.target.value});
        }
    }


    render() {
        return (
            <div>

                {
                    this.state.showOverlay && (<OverlayProcessing innerClass="RCM_Div_Overlay"/>)
                }
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6" align="right">
                        <input 
                            type="text" 
                            placeholder="Search Client"
                            value={this.state.searchClientStr} 
                            className="RCM_form_control" onChange={(e) => this.handleOnSearchClientChange(e)}/>
                    </div>
                </div>
                <div id="RCM_Admin_ClientVisibility_List_Table" className="RCM_Admin_ClientVisibility_List_Table mt-1">
                 <BootstrapTable
                        id="admin_clientvisibility_clientlist"
                        keyField='orgId'
                        data={this.state.filterdClientList}
                        columns={getClientsListColumn(this.handleOnClientSelect)}
                        headerClasses="row-bg"
                        bordered={false}
                        classes="RCM_two_level_table1"
                        rowClasses='RCM_tow_level_table1_row_animation'
                        noDataIndication={MsgForNoData}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        refresh: store.app.refresh,
        env: store.app.env,
        selectedUser: store.adminClientVisibility.selectedUser
    };
}

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientsList));
